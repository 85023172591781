import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { navVariants } from "../utils/motion";
import Loading from "./Loading";
import { urlFor, client } from '../client';
import { TitleText } from "./CustomText";

const Blog = () => {
    const [media, setMedia] = useState([]);
    const navigate = useNavigate();

    const handleClick = () => {
        navigate("/blogs");
    };

    useEffect(() => {
        const query = '*[_type == "blog"]';
        client.fetch(query).then((data) => {
            setMedia(data.slice(-1))
        });

    }, []);

    console.log(media);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    };

    return (
        <>
            <TitleText title="Visita nuestro Blog!" textStyles="text-black text-3xl py-3" />

            <section className="flex flex-col justify-center items-center w-full">
                <motion.div
                    variants={navVariants}
                    initial="hidden"
                    whileInView="show"
                    className="w-full flex flex-col items-center"
                >
                    {/* Líneas decorativas */}
                    <div className="h-[0.09rem] w-9/12 bg-black mt-8" />
                    <div className="h-[0.6rem] w-9/12 bg-black mt-16 mb-10" />

                    {/* Contenido dividido en dos columnas */}
                    <div className="flex w-9/12">
                        {media.map((file) => (
                            <div key={file._id} className="flex max-lg:flex-col w-full">
                                {/* Columna izquierda: PDF o contenido multimedia */}
                                <div className="w-1/2 max-lg:w-full flex flex-col justify-center items-center">
                                    {file.imgUrl && file.imgUrl.asset && file.imgUrl.asset._ref.includes("image") ? (
                                        <img src={urlFor(file.imgUrl)} alt={file.name} />
                                    ) : null}

                                    {file.link && file.link.asset && file.link.asset._ref.includes("file") ? (
                                        file.link.asset._ref.includes("pdf") ? (
                                            <iframe
                                                src={urlFor(file.link)}
                                                width="100%"
                                                height="500px"
                                                title={file.name}
                                            >
                                                Tu navegador no soporta la previsualización de PDFs.
                                            </iframe>
                                        ) : file.link.asset._ref.includes("mp4") ? (
                                            <video controls>
                                                <source src={urlFor(file.link)} type="video/mp4" />
                                                Tu navegador no soporta la etiqueta de video.
                                            </video>
                                        ) : (
                                            <a
                                                href={urlFor(file.link)}
                                                download={file.title}
                                                target="_blank"
                                                rel="noopener noreferrer"
                                            >
                                                Descargar {file.title}
                                            </a>
                                        )
                                    ) : null}

                                    {/* Fecha debajo del contenido multimedia */}
                                    <p className="text-xs text-gray-500 mt-4">{formatDate(file._createdAt)}</p>
                                </div>

                                <div className="w-1/2 max-lg:w-full max-lg:pt-2 max-lg:pl-0 flex flex-col pl-10">
                                    <h1 className="text-4xl font-bold">{file.title}</h1>
                                    <p className="text-base text-gray-600 mt-4">{file.description}</p>
                                    <span className="text-xs text-gray-400 mt-2">{file.author}</span>
                                    <button onClick={handleClick}>
                                        <a
                                            rel="noopener noreferrer"
                                            className="text-xs text-gray-400 mt-2 underline"
                                        >
                                            Ver mas
                                        </a>
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>

                    {/* Líneas decorativas */}
                    <div className="h-[0.09rem] w-9/12 bg-black mt-8" />
                    <div className="h-[0.6rem] w-9/12 bg-black mt-16 mb-10" />
                </motion.div>
            </section>
        </>
    );
};

export default Blog;
