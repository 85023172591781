import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { navVariants } from "../utils/motion";
import { useNavigate } from "react-router-dom";
import Loading from "./Loading";
import { TitleText } from "./CustomText";
import { client, urlFor } from "../client";

const CompleteBlog = () => {
    const [mediaList, setMediaList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        const query = '*[_type == "blog"]';
        client.fetch(query).then((data) => {
            setMediaList(data); // Set the fetched blog data
        }).catch((error) => console.error("Error fetching media:", error));
    }, []);

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: 'long', day: 'numeric' };
        return new Date(dateString).toLocaleDateString('es-ES', options);
    };

    const handleBack = () => {
        navigate("/"); // Navigate to home
    };

    return (
        <section className="relative flex flex-col h-full justify-center items-center bg-gradient-to-r from-gray-100 to-gray-300 py-10">
            <motion.div
                variants={navVariants}
                initial="hidden"
                whileInView="show"
                className="w-full flex flex-col items-center space-y-12"
            >
                <div className="flex items-center mt-4 space-x-4">
                    <button onClick={handleBack} className="text-4xl pt-0 max-lg:text-3xl p-1 rounded-2xl shadow-lg bg-white hover:bg-gray-200 transition-all">
                        ←
                    </button>
                    <TitleText title="Publicaciones" textStyles="text-center text-4xl max-lg:text-3xl" />
                </div>

                {mediaList.length > 0 ? (
                    mediaList.map((media, index) => (
                        <motion.div key={index} className="w-10/12 flex flex-col items-center bg-white rounded-2xl shadow-lg overflow-hidden transform hover:scale-105 transition-all">
                            <div className="h-[0.6rem] w-full bg-gradient-to-r from-gray-400 to-gray-600 mt-8 mb-10" />

                            <div className="flex max-lg:flex-col w-full p-4">
                                <div className="w-1/2 max-lg:w-full flex flex-col justify-center items-center">
                                    {media.imgUrl && media.imgUrl.asset && media.imgUrl.asset._ref.includes("image") ? (
                                        <img
                                            src={urlFor(media.imgUrl)} // Sanity URL function
                                            alt={media.title}
                                            className="rounded-lg shadow-md mx-auto"
                                        />
                                    ) : media.link && media.link.asset ? (
                                        media.link.asset._ref.includes("pdf") ? (
                                            <iframe
                                                src={urlFor(media.link)}
                                                width="100%"
                                                height="500px"
                                                title={media.title}
                                                className="mx-auto"
                                            >
                                                Tu navegador no soporta la previsualización de PDFs.
                                            </iframe>
                                        ) : media.link.asset._ref.includes("mp4") ? (
                                            <video controls className="rounded-lg mx-auto w-[50%] h-auto object-cover">
                                                <source src={urlFor(media.link)} type="video/mp4" />
                                                Tu navegador no soporta la etiqueta de video.
                                            </video>
                                        ) : null
                                    ) : null}
                                    {/* Date below the media */}
                                    <p className="text-sm text-gray-500 mt-2 text-center">
                                        Publicado el {formatDate(media._createdAt)}
                                    </p>
                                </div>

                                <div className="w-1/2 max-lg:w-full max-lg:pl-0 max-lg:pt-2 flex flex-col pl-10">
                                    <h2 className="text-4xl font-bold">{media.title}</h2>
                                    <p className="text-lg font-light text-gray-700">{media.description}</p>
                                    <span className="text-base font-extralight opacity-70">{media.author}</span>
                                    {media?.articulo && (
                                        <a
                                            href={urlFor(media?.articulo)}
                                            download={media.title}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            className="text-base font-light text-gray-700 underline cursor-pointer"
                                        >
                                            Descarga el articulo completo!
                                        </a>
                                    )}
                                </div>
                            </div>

                            <div className="h-[0.6rem] w-full bg-gradient-to-r from-gray-400 to-gray-600 mt-16 mb-10" />
                        </motion.div>
                    ))
                ) : (
                    <Loading />
                )}
            </motion.div>
        </section>
    );
};

export default CompleteBlog;
